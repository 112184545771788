import { useHttp } from 'hooks/use-fetch';
import {
  createWaypointSuggestions,
  deleteWaypointSuggestions,
  fetchStandardWaypointsList,
  fetchWaypointSuggestionsList,
} from 'pages/Dashboard/services/waypointSuggestion.service';
import { fetchPatientWaypointList } from 'pages/Dashboard/services/waypoints.services';
import {
  CreateWaypointSuggestionsPayload,
  WaypointSuggestion,
} from 'pages/Dashboard/types/waypointSuggestion.types';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { wayPointSuggestionUrls, wayPointsUrls } from 'utils/apiUrls';
import { filterParentWaypoints } from 'utils/appUtils';

interface UseWaypointSuggestionsProps {
  providerId: string | undefined;
  patientId: string | undefined;
  onError: (message: string) => void;
}

export default function useWaypointSuggestions({
  providerId,
  patientId,
  onError,
}: UseWaypointSuggestionsProps) {
  const { http } = useHttp();
  const queryClient = useQueryClient();

  const fetchStandardWaypointsResult = useQuery(
    [`${wayPointsUrls.listWayPoints.queryUrl}`, providerId],
    {
      queryFn: fetchStandardWaypointsList(http.get),
      onError: () => onError('Error fetching standard waypoints'),
      enabled: !!providerId && !!patientId,
    },
  );

  const fetchPatientWaypointsResult = useQuery(
    [`${wayPointsUrls.listPatientWaypoints.queryUrl}`, patientId],
    {
      queryFn: fetchPatientWaypointList(http.get),
      onError: () => onError('Error fetching patient waypoints'),
      enabled: !!providerId && !!patientId,
    },
  );

  const fetchWaypointSuggestionsResult = useQuery(
    [`${wayPointSuggestionUrls.listWaypointSuggestions.queryUrl}`, providerId, patientId],
    {
      queryFn: fetchWaypointSuggestionsList(http.get),
      onError: () => onError('Error fetching waypoint suggestions'),
      enabled: !!providerId && !!patientId,
    },
  );

  const deleteWaypointSuggestionsResult = useMutation({
    // eslint-disable-next-line max-len
    mutationFn: () => deleteWaypointSuggestions(http.delete, providerId as string, patientId as string),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          `${wayPointSuggestionUrls.deleteWaypointSuggestions.queryUrl}`,
          providerId,
          patientId,
        ],
      });
    },
  });

  const createWaypointSuggestionsResult = useMutation({
    // eslint-disable-next-line max-len
    mutationFn: (payload: CreateWaypointSuggestionsPayload) => createWaypointSuggestions(http.post, providerId as string, patientId as string, {
      ...payload,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          `${wayPointSuggestionUrls.createWaypointSuggestions.queryUrl}`,
          providerId,
          patientId,
        ],
      });
    },
    onError: () => onError('Error sending waypoint suggestions'),
  });

  const revokeWaypointSuggestions = useCallback(
    () => deleteWaypointSuggestionsResult.mutateAsync(),
    [deleteWaypointSuggestionsResult],
  );

  const sendWaypointSuggestions = useCallback(
    (suggestions: Partial<WaypointSuggestion>[]) => createWaypointSuggestionsResult.mutateAsync({
      suggestions,
    }),
    [createWaypointSuggestionsResult],
  );

  return {
    standardWaypoints: fetchStandardWaypointsResult.data?.waypoints ?? [],
    patientWaypoints: filterParentWaypoints(fetchPatientWaypointsResult.data?.waypoints),
    waypointSuggestions: fetchWaypointSuggestionsResult.data?.suggestions ?? [],
    revokeWaypointSuggestions,
    sendWaypointSuggestions,
    isFetchingPatientWaypoints: fetchPatientWaypointsResult.isFetching,
    isFetchingWaypointSuggestions: fetchWaypointSuggestionsResult.isFetching,
    refetchPatientWaypoints: fetchPatientWaypointsResult.refetch,
    refetchWaypointSuggestions: fetchWaypointSuggestionsResult.refetch,
  };
}
