import { useTheme } from '@emotion/react';
import { GAUGE_MAXIMUM } from 'pages/Dashboard/utils/trackingUtils';
import * as React from 'react';
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from 'recharts';

type GaugeData = {value: number};

interface GaugeChartProps {
  color: string;
  data: GaugeData[];
  value: string;
}

export default function GaugeChart({ color, value, data }: GaugeChartProps): JSX.Element {
  const theme = useTheme();


  const renderCustomLabel = (props: {[key: string]: unknown}) => {
    const x = !props.name ? parseInt(props.cx as string, 10) - 60
      : parseInt(props.cx as string, 10) + 50;

    const y = parseInt(props.cy as string, 10) + 45;

    return (
      <text fill='grey' fontSize='0.75rem' x={x} y={y}>
        {!props.name ? '0' : GAUGE_MAXIMUM}
      </text>
    );
  };

  return (
    <ResponsiveContainer width='100%' height={150}>
      <PieChart>
        <Pie
          startAngle={225}
          endAngle={-45}
          innerRadius='70%'
          data={data}
          dataKey='value'
          labelLine={false}
          blendStroke
          isAnimationActive={false}
          label={renderCustomLabel}
        >
          <Cell fill={color} />
          <Cell fill={theme.palette.mode === 'dark' ? 'grey' : '#d2d2d2'} />
          <Label fill={theme.palette.text.primary} {...theme.typography.h3} value={value} position='center' />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
