import * as React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Control, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { CheckCircle } from '@mui/icons-material';
import ContentLoader from 'components/Common/ContentLoader';
import Input from 'components/Common/Input';
import RewardPoints from 'pages/Rewards/components/RewardPoints';
import { useHttp } from 'hooks/use-fetch';
import sendReferralEmail from 'pages/Rewards/services/referral-email.services';
import IReferralEmailPayload from 'pages/Rewards/types/referral-email.types';
import theme from 'theme';
import trackMixpanelEvent from 'pages/Rewards/utilMethods';
import { MixpanelEventName } from 'pages/Rewards/types/mixpanel.types';

export const referralFormInitialState: IReferralEmailPayload = {
  firstName: '',
  lastName: '',
  email: '',
};

export interface IReferralFormProps {
  points: number;
}

export default function ReferralForm({ points = 10 }: IReferralFormProps) {
  const { http, providerId } = useHttp();
  const {
    mutate: sendEmail,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (payload: IReferralEmailPayload) => sendReferralEmail(http.post, providerId, {
      request: payload,
    }),
    retry: 2,
  });

  const formMethods = useForm<IReferralEmailPayload>({
    defaultValues: referralFormInitialState,
  });
  const {
    formState: { errors, submitCount },
  } = formMethods;

  const trackEvents = React.useCallback(() => {
    trackMixpanelEvent(MixpanelEventName.SEND_REFERRAL_BUTTON_CLICKED, {
      'validation error on first name': errors.firstName ? 'yes' : 'no',
      'validation error on last name': errors.lastName ? 'yes' : 'no',
      'validation error on email': errors.email ? 'yes' : 'no',
    });
  }, [errors]);

  const onSubmit: SubmitHandler<IReferralEmailPayload> = (data) => {
    trackEvents();
    sendEmail(data);
  };

  const resetFormState = () => {
    trackMixpanelEvent(MixpanelEventName.SEND_ANOTHER_REFERRAL_BUTTON_CLICKED);
    formMethods.reset(referralFormInitialState);
  };

  const typographyProps = {
    fontSize: 24,
    fontWeight: 400,
  };

  React.useEffect(() => {
    if (submitCount > 0 && Object.keys(errors).length > 0) {
      trackEvents();
    }
  }, [submitCount, errors, trackEvents]);

  if (formMethods.formState.isSubmitSuccessful) {
    return (
      <ContentLoader isFetching={isLoading} isError={isError} minHeight={200}>
        <Box my={6.5} textAlign='center' width='100%'>
          <Box display='flex' justifyContent='center' alignItems='center' mb={3}>
            <CheckCircle color='success' fontSize='large' />
            <Typography {...typographyProps} ml={1.25}>
              Success!
            </Typography>
          </Box>
          <Typography {...typographyProps} color={theme.custom.colors.lightTextSecondary} mb={3}>
            Referral email sent
          </Typography>
          <Button variant='contained' size='large' onClick={resetFormState}>
            SEND ANOTHER REFERRAL
          </Button>
        </Box>
      </ContentLoader>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} display='flex'>
            <Typography variant='body1' mr='3px'>
              Earn
            </Typography>
            <RewardPoints rewardPoints={points} size='medium' />
            <Typography variant='body1' ml='3px'>
              when the referred clinician signs up!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Input
                  name='firstName'
                  control={formMethods.control as unknown as Control<FieldValues>}
                  label={"Clinicians's first name"}
                  rules={{
                    required: 'First name is required',
                  }}
                  inputProps={{
                    'data-testid': 'clinician-first-name',
                    autoComplete: 'new-password',
                  }}
                  hideLastPass
                />
              </Grid>
              <Grid item xs={8}>
                <Input
                  name='lastName'
                  control={formMethods.control as unknown as Control<FieldValues>}
                  label={"Clinician's last name"}
                  rules={{
                    required: 'Last name is required',
                  }}
                  inputProps={{
                    'data-testid': 'clinician-last-name',
                    autoComplete: 'new-password',
                  }}
                  hideLastPass
                />
              </Grid>
              <Grid item xs={10}>
                <Input
                  name='email'
                  control={formMethods.control as unknown as Control<FieldValues>}
                  label={"Clinician's email"}
                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'Invalid email format',
                    },
                  }}
                  inputProps={{
                    'data-testid': 'clinician-email',
                    autoComplete: 'new-password',
                  }}
                  hideLastPass
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' type='submit'>
              SEND REFERRAL
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
