import * as React from 'react';


interface ISideNavCtx {
  drawerWidth: string,
  isFullNav: boolean,
  setIsFullNav: React.Dispatch<React.SetStateAction<boolean>>,
}

// eslint-disable-next-line import/prefer-default-export
export const SideNavCtx = React.createContext<Partial<ISideNavCtx>>({});
