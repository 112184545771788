import * as React from 'react';
import * as spaUrls from 'utils/spaUrls';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Checkbox, CircularProgress, Link, Typography } from '@mui/material';
import usePatientProviderMap from 'pages/ScalesManifest/hooks/usePatientProviderMap';
import Filters, { TFilters } from 'pages/ScalesManifest/components/Filters';
import usePDFExport from 'pages/ScalesManifest/hooks/usePDFExport';
import { TableData } from 'pages/ScalesManifest/types/scales-manifest.types';
import GetAppIcon from '@mui/icons-material/GetApp';
import useScales from 'pages/ScalesManifest/hooks/useScales';
import { format } from 'date-fns';
import PatientScaleDetailsDialog, { Patient } from 'pages/ScalesManifest/PatientScaleDetailsDialog';
import LoadingButton from 'components/Common/LoadingButton';
import trackMixpanelEvent from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import { useHttp } from 'hooks/use-fetch';

interface TableColumn {
  id: 'providerName' | 'patientName' | 'scale' | 'score' | 'takenOn' | 'attemptId';
  label: string;
  minWidth?: number;
}

const tableColumns: readonly TableColumn[] = [
  { id: 'providerName', label: 'Provider name', minWidth: 100 },
  {
    id: 'patientName',
    label: 'Patient name',
    minWidth: 100,
  },
  {
    id: 'scale',
    label: 'Scale',
    minWidth: 100,
  },
  {
    id: 'score',
    label: 'Score',
    minWidth: 100,
  },
  {
    id: 'takenOn',
    label: 'Taken on',
    minWidth: 100,
  },
  {
    id: 'attemptId',
    label: 'Answers',
    minWidth: 100,
  },
];

export default function ScalesManifest() {
  const { providerId } = useHttp();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRows, setSelectedRows] = React.useState({} as { [key: string]: TableData });
  const [selectedRow, setSelectedRow] = React.useState({} as TableData);
  const [filters, setFilters] = React.useState({} as TFilters);

  const [enableFetching, setEnableFetching] = React.useState(true);
  const {
    scaleNames,
    patientNames,
    providerNames,
    isFetching: isFetchingProviderPatientMap,
  } = usePatientProviderMap('patient-provider-map', filters?.providerName?.value);


  const {
    totalCount,
    scales,
    isFetching: isFetchingScales,
    isError,
  } = useScales({
    categoryId: filters.scale?.value || '',
    providerId: filters.providerName?.value || '',
    patientId: filters.patientName?.value || '',
    startDate: filters.startDate,
    endData: filters.endDate,
    attemptId: '',
    page,
    size: rowsPerPage,
    enabled: enableFetching,
    billingAdminId: providerId,
  });

  const tableRows = React.useMemo(() => scales, [scales]);

  const providerNameOptions = React.useMemo(
    () => Object.keys(providerNames).map((id) => ({
      label: providerNames[id].name,
      value: id,
    })),
    [providerNames],
  );

  const patientNameOptions = React.useMemo(
    () => Object.keys(patientNames).map((id) => ({
      label: patientNames[id].name,
      value: id,
    })),
    [patientNames],
  );

  const scaleOptions = React.useMemo(
    () => Object.keys(scaleNames).map((id) => ({
      label: scaleNames[id],
      value: id,
    })),
    [scaleNames],
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const goToAnswers = React.useCallback((row: TableData) => {
    setSelectedRow(row);
    trackMixpanelEvent(MixpanelEventName.SEE_ANSWERS_CLICKED, {
      scale: row.attemptId,
      'patient id': row.patientId,
      'provider id': row.providerId,
    });
  }, []);

  const rows: TableData[] = React.useMemo(() => {
    const { patientName, providerName, scale } = filters;
    const updatedRows = [...tableRows];
    const filteredRows = updatedRows.reduce((acc: TableData[], row: TableData) => {
      const isPatientNameMatch = !patientName?.value || patientName?.value === row.patientId;
      const isProviderNameMatch = !providerName?.value || providerName?.value === row.providerId;
      const isScaleMatch = !scale?.value || scale?.value === row.categoryId;

      if (
        isPatientNameMatch
        && isProviderNameMatch
        && isScaleMatch
        && patientNames[row.patientId]?.name
      ) {
        acc.push({
          ...row,
          patientName: patientNames?.[row.patientId]?.name,
          patientDOB: patientNames[row.patientId]?.dob,
          providerName: providerNames[row.providerId]?.name,
          scale: row.categoryAbbreviation,
          takenOn: format(new Date(row.attemptedAt), 'M/d/yyyy'),
          score: `${row.attemptResult.score}/${row.attemptResult.totalScore}`,
        });
      }

      return acc;
    }, []);
    return filteredRows;
  }, [filters, patientNames, providerNames, tableRows]);

  const targetRef = React.useRef<HTMLDivElement>(null);

  const selectedRowsData = React.useMemo(() => Object.values(selectedRows), [selectedRows]);

  const renderComponent = React.useCallback(
    (ref, details, questions) => (
      <Patient questions={questions} targetRef={ref} details={details} />
    ),
    [],
  );

  const { isExecuting, executePDFExport } = usePDFExport({
    exportingRows: selectedRowsData,
    renderComponent,
    afterExecution: () => {
      trackMixpanelEvent(MixpanelEventName.DOWNLOAD_PDF_CLICKED, {
        scales: selectedRowsData.map((row) => row.attemptId).join(', '),
        location: 'Reports table',
      });
      setSelectedRows({});
    },
  });

  const onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const selectedRowsMap = rows.reduce((acc: { [key: string]: TableData }, row: TableData) => {
        acc[row.attemptId] = row;
        return acc;
      }, {});
      setSelectedRows({
        ...selectedRowsMap,
      });
    }
    else {
      setSelectedRows({});
    }
  };

  const onSelectRow = (e: React.ChangeEvent<HTMLInputElement>, row: TableData) => {
    setSelectedRows((prevRows) => {
      const updatedRows = { ...prevRows };
      if (e.target.checked) {
        updatedRows[row.attemptId] = row;
        return updatedRows;
      }
      else {
        delete updatedRows[row.attemptId];
        return updatedRows;
      }
    });
  };

  const numberOfSelectedRows = React.useMemo(() => selectedRowsData.length, [selectedRowsData]);

  const isFetching = React.useMemo(
    () => isFetchingProviderPatientMap || isFetchingScales,
    [isFetchingProviderPatientMap, isFetchingScales],
  );

  const renderColumn = React.useCallback(
    (column: TableColumn, row: TableData) => {
      if (column.id === 'patientName') {
        return (
          <Link
            component='a'
            underline='hover'
            href={spaUrls.mainNav.patientDetail(row.patientId)}
            target='_blank'
            onClick={() => trackMixpanelEvent(MixpanelEventName.PATIENT_NAME_CLICKED, {
              'patient id': row.patientId,
            })}
          >
            {row[column.id]}
          </Link>
        );
      }
      else if (column.id === 'attemptId') {
        return (
          <Link onClick={() => goToAnswers(row)} component='button' underline='hover'>
            See answers
          </Link>
        );
      }
      return row[column.id];
    },
    [goToAnswers],
  );

  const onChangeFilters = React.useCallback((callback: React.SetStateAction<TFilters>) => {
    setEnableFetching(false);
    setPage(0);
    setFilters(callback);
    setEnableFetching(true);
  }, []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', p: 2 }} ref={targetRef}>
      <Typography
        variant='h4'
        gutterBottom
        justifyContent='space-between'
        display='flex'
        width='100%'
      >
        Patient Scales
      </Typography>
      <Filters
        filters={filters}
        setFilters={onChangeFilters}
        providerNameOptions={providerNameOptions}
        patientNameOptions={patientNameOptions}
        scaleOptions={scaleOptions}
        isFetching={isFetching}
      />
      <LoadingButton
        sx={{ ml: 1.9 }}
        variant='contained'
        startIcon={<GetAppIcon />}
        onClick={executePDFExport}
        label='DOWNLOAD PDF'
        isLoading={isExecuting}
        disabled={!selectedRowsData.length}
      />
      <TableContainer
        sx={{
          minHeight: 600,
          display: 'flex',
          alignItems: isFetching ? 'center' : 'flex-start',
          justifyContent: 'center',
        }}
      >
        {isFetching && <CircularProgress data-testid='scales-loader' />}
        {!isFetching && isError && (
          <Typography variant='h5' mt={10} color='warning'>
            Error loading data
          </Typography>
        )}
        {!isFetching && !isError && rows.length === 0 && (
          <Typography variant='h5' mt={10}>
            No data found for the selected filters
          </Typography>
        )}
        {!isFetching && rows.length > 0 && (
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    color='primary'
                    indeterminate={numberOfSelectedRows > 0 && numberOfSelectedRows < rows.length}
                    checked={numberOfSelectedRows === rows.length}
                    onChange={onSelectAll}
                    disabled={isExecuting}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell>
                {tableColumns.map((column) => (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: TableData) => (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.attemptId + row.patientId}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      color='primary'
                      checked={!!selectedRows[row.attemptId]?.attemptId}
                      onChange={(e) => onSelectRow(e, row)}
                      disabled={isExecuting}
                    />
                  </TableCell>
                  {tableColumns.map((column) => (
                    <TableCell key={column.id}>{renderColumn(column, row)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <PatientScaleDetailsDialog
        selectedRow={{ ...selectedRow, metaInfo: selectedRow?.attemptResult?.metaInfo }}
        setSelectedRow={setSelectedRow}
      />
    </Paper>
  );
}
