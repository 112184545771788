import { subDays, subMonths, subYears, parse, compareAsc, format } from 'date-fns';
import {
  Mood,
  MoodOptions,
  MoodTrackingAverage,
  MoodTrackingList,
} from 'pages/Dashboard/types/moods.types';
import {
  GroupedWaypoints,
  NestedWaypoints,
  TimeInterval,
  WaypointTracking,
  WaypointTrackingAPIResponse,
  WaypointValues,
} from 'pages/Dashboard/types/waypoints.types';
import Theme from 'theme';
import { formatToBrowserDate } from 'utils/dateUtils';

export const GAUGE_MAXIMUM = 10;

export enum TimeFrameOptions {
  LAST_30_DAYS = 'last 30 days',
  LAST_3_MONTHS = 'last 3 months',
  LAST_6_MONTHS = 'last 6 months',
  LAST_YEAR = 'last year',
}

export enum ArrowTypes {
  SINGLE_UP = 'Single up',
  SINGLE_DOWN = 'Single down',
  DOUBLE_UP = 'Double up',
  DOUBLE_DOWN = 'Double down',
}

export const defaultMoodTracking = {
  moods: [],
};

export const defaultWaypointTracking: WaypointTrackingAPIResponse = {
  waypoints: [],
};

export function getNestedWaypointsListFromFlatList(waypoints: WaypointTracking[]) {
  const nodeMap: { [key: string]: NestedWaypoints } = {};
  const roots: NestedWaypoints[] = [];

  waypoints.forEach((waypoint) => {
    const waypointObj = waypoint;
    nodeMap[waypointObj.waypoint.id] = {
      waypoint: { ...waypointObj.waypoint },
      value: waypoint.value,
    };
  });

  waypoints.forEach((waypoint) => {
    const waypointObj = waypoint.waypoint;
    const currentWaypoint = nodeMap[waypointObj.id];
    const parentTreeNode = nodeMap[waypointObj.parentWaypoint?.id as string];
    if (parentTreeNode) {
      // children are the subwaypoints
      if (!parentTreeNode.waypoint.children) {
        parentTreeNode.waypoint.children = [];
      }
      parentTreeNode.waypoint.children.push(currentWaypoint);
    }
    else {
      roots.push(currentWaypoint);
    }
  });

  return roots;
}

export function getWaypointDataFromId(waypoints: WaypointTracking[], waypointId: string) {
  return waypoints.find((w) => w.waypoint.id === waypointId);
}

export function getFilteredWaypointsData(
  currentWaypoint: WaypointTracking[],
  previousWaypoint: WaypointTracking[],
) {
  const allWaypoints: WaypointTracking[] = [];
  currentWaypoint.forEach((w) => {
    allWaypoints.push({ ...w });
  });
  previousWaypoint.forEach((w) => {
    const isIncludedInAllWaypoints = allWaypoints.some(
      (currentWp) => currentWp.waypoint.id === w.waypoint.id,
    );
    if (isIncludedInAllWaypoints) {
      return null;
    }
    allWaypoints.push({ ...w });
    return null;
  });

  const allWaypointsNestedList = getNestedWaypointsListFromFlatList(allWaypoints);
  const grpAll = groupWaypointsByCategory(allWaypointsNestedList);
  return grpAll;
}

export function groupWaypointsByCategory(waypoints: NestedWaypoints[]) {
  const waypointsData: GroupedWaypoints = {};

  waypoints.forEach((data) => {
    const {
      waypoint: { category: waypointCategory },
    } = data;
    const category = waypointCategory.toLowerCase();

    if (Object.hasOwn(waypointsData, category.toLowerCase())) {
      waypointsData[category.toLowerCase()] = [...waypointsData[category.toLowerCase()], data];
    }
    else {
      waypointsData[category] = [data];
    }
  });
  return waypointsData;
}

export function getWaypointColorByIndex(index: number) {
  // Get the index within the valid range of indices
  const cycleIndex = index % Theme.custom.waypoints.length;
  return Theme.custom.waypoints[cycleIndex];
}

export function getArrows(currentLabel: string, prevLabel: string) {
  const trackingOrder = ['less', 'typical', 'more'];
  const currentLabelValue = trackingOrder.indexOf(currentLabel);
  const prevLabelValue = trackingOrder.indexOf(prevLabel);
  if (currentLabelValue < 0 || prevLabelValue < 0) {
    return null;
  }
  const orderDifference = currentLabelValue - prevLabelValue;
  if (orderDifference > 0) {
    if (orderDifference === 1) {
      return 'Single up';
    }

    if (orderDifference === 2) {
      return 'Double up';
    }
  }
  else {
    if (Math.abs(orderDifference) === 1) {
      return 'Single down';
    }
    if (Math.abs(orderDifference) === 2) {
      return 'Double down';
    }
  }
  return null;
}

function getLabelFormattedText(label: string, waypointName?: string) {
  if (waypointName === 'quality') {
    switch (label) {
      case 'less': return 'lower';
      case 'more': return 'higher';
      default: return label;
    }
  }

  if (waypointName === 'personal' || waypointName === 'work') {
    switch (label) {
      case 'less': return 'worse';
      case 'more': return 'better';
      default: return label;
    }
  }
  return label;
}

export function getWaypointFormattedValues(value: WaypointValues, waypointName?: string) {
  const roundedOffValue = Math.round(value / 10) * 10;
  const labelMap: { [key: number]: string } = {
    10: 'less',
    20: 'typical',
    30: 'more',
  };
  if (!value) {
    return 'No data';
  }
  if (value < 10) {
    return getLabelFormattedText('less', waypointName);
  }
  if (value > 30) {
    return getLabelFormattedText('more', waypointName);
  }
  const label = labelMap?.[roundedOffValue];
  if (!label) {
    return 'No data';
  }
  return getLabelFormattedText(label, waypointName);
}

export function getMoodOptions(
  currMoodData: MoodTrackingAverage[],
  previousMoodData: MoodTrackingAverage[],
) {
  const moodOptions: MoodOptions[] = [];
  currMoodData.forEach((mood) => {
    let label = '';
    switch (mood.mood) {
      case Mood.MOOD_HAPPINESS:
        label = 'Happiness';
        break;
      case Mood.MOOD_ANXIOUSNESS:
        label = 'Anxiousness';
        break;
      case Mood.MOOD_MOTIVATION:
        label = 'Motivation';
        break;
      default:
        label = 'Unspecified';
    }
    let previousValue = 0;
    previousMoodData.forEach((preMood) => {
      if (preMood.mood === mood.mood) {
        previousValue = preMood.value;
      }
    });
    moodOptions.push({
      mood: mood.mood,
      currentValue: mood.value,
      previousValue,
      label,
    });
  });
  return [
    ...moodOptions.filter((moodOption) => moodOption.mood === Mood.MOOD_HAPPINESS),
    ...moodOptions.filter((moodOption) => moodOption.mood === Mood.MOOD_MOTIVATION),
    ...moodOptions.filter((moodOption) => moodOption.mood === Mood.MOOD_ANXIOUSNESS),
  ];
}

function getInterval(previous: Date, presentDay: Date, previousToLast: Date, datePrior: Date) {
  return {
    currInterval: {
      start: formatToBrowserDate(previous),
      end: formatToBrowserDate(presentDay),
    },
    previousInterval: {
      start: formatToBrowserDate(previousToLast),
      end: formatToBrowserDate(datePrior),
    },
  };
}

export function getTimeFrameInterval(timeFrame: string) {
  const presentDay = new Date();

  switch (timeFrame) {
    case TimeFrameOptions.LAST_30_DAYS: {
      const previous = subDays(presentDay, 30);
      const datePrior = subDays(previous, 1);
      const previousToLast = subDays(datePrior, 30);
      return getInterval(previous, presentDay, previousToLast, datePrior);
    }
    case TimeFrameOptions.LAST_3_MONTHS: {
      const previous = subMonths(presentDay, 3);
      const datePrior = subDays(previous, 1);
      const previousToLast = subMonths(datePrior, 3);
      return getInterval(previous, presentDay, previousToLast, datePrior);
    }
    case TimeFrameOptions.LAST_6_MONTHS: {
      const previous = subMonths(presentDay, 6);
      const datePrior = subDays(previous, 1);
      const previousToLast = subMonths(datePrior, 6);
      return getInterval(previous, presentDay, previousToLast, datePrior);
    }
    case TimeFrameOptions.LAST_YEAR: {
      const previous = subYears(presentDay, 1);
      const datePrior = subDays(previous, 1);
      const previousToLast = subYears(datePrior, 1);
      return getInterval(previous, presentDay, previousToLast, datePrior);
    }
    default:
      return getInterval(presentDay, presentDay, presentDay, presentDay);
  }
}

export function getFormattedChartData(chartData: MoodTrackingList[]) {
  const sortedByDate = chartData.sort((data1, data2) => {
    const parsedDateA = parse(`${data1.date.year}-${data1.date.month}-${data1.date.day}`, 'yyyy-MM-dd', new Date());
    const parsedDateB = parse(`${data2.date.year}-${data2.date.month}-${data2.date.day}`, 'yyyy-MM-dd', new Date());
    return compareAsc(parsedDateA, parsedDateB);
  });

  return sortedByDate.map((data) => {
    const { date } = data;
    return {
      value: data.value,
      date: `${date.month}/${date.day}`,
    };
  });
}

export function getGaugeData(current: number) {
  return [{ value: current }, { value: GAUGE_MAXIMUM - current }];
}

export function getGaugeColor(mood: Mood, moodValue: number) {
  const isAnxiousness = mood === Mood.MOOD_ANXIOUSNESS;

  if (isAnxiousness) {
    if (moodValue >= 0 && moodValue <= 2) {
      return 'green';
    }
    else if (moodValue >= 3 && moodValue <= 6) {
      return 'orange';
    }
    else return 'red';
  }

  if (moodValue >= 0 && moodValue <= 3) {
    return 'red';
  }
  else if (moodValue >= 4 && moodValue <= 7) {
    return 'orange';
  }
  else return 'green';
}

export function formatIntervalDate(interval: TimeInterval) {
  let dateFormat = 'LLL d';
  const currYear = format(new Date(), 'yyyy');
  const { start: intervalStartDate, end: intervalEndDate } = interval;

  const startYear = format(new Date(intervalStartDate), 'yyyy');
  const endYear = format(new Date(intervalEndDate), 'yyyy');

  const hasYearChanged = startYear !== currYear || endYear !== currYear;

  if (hasYearChanged) dateFormat = 'LLL d yyyy';

  return `${format(new Date(intervalStartDate), dateFormat)} - ${format(
    new Date(intervalEndDate),
    dateFormat,
  )}`;
}
