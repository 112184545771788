import * as React from 'react';
import { Box, Tab, Tabs } from '@mui/material';

export type TabContext = {
  header: string;
  content: React.ReactNode;
};

interface TabCompomnentProps {
  tabContext: TabContext[];
  variant?: 'standard' | 'scrollable' | 'fullWidth';
}

export default function TabComponent({ tabContext, variant }: TabCompomnentProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Tabs variant={variant ?? 'standard'} value={value} onChange={handleChange}>
        {tabContext.map((tabs, index) => (
          <Tab label={tabs.header.toUpperCase()} key={tabs.header.toLowerCase()} />
        ))}
      </Tabs>
      {tabContext.map((tabs, index) => (value === index ? tabs.content : null))}
    </Box>
  );
}
