import * as React from 'react';
import { Grid, TableCell, capitalize } from '@mui/material';
import { format } from 'date-fns';
import { useHttp } from 'hooks/use-fetch';
import { useParams } from 'react-router-dom';
import { Category, Scale } from 'pages/Dashboard/types/whoiam.types';
import PatientScaleDetailsDialog from 'pages/ScalesManifest/PatientScaleDetailsDialog';
import { TableData } from 'pages/ScalesManifest/types/scales-manifest.types';
import { PatientCtx } from 'pages/Dashboard/constants';
import { GoogleDate, googleDateToDateString } from 'utils/dateUtils';
import ScaleResult from 'pages/Dashboard/components/ScaleResult';

interface PatientRatingScalesProps {
  ratingScales: Category[];
}

export default function PatientRatingScales({
  ratingScales,
}: PatientRatingScalesProps): JSX.Element {
  const { isBillingAdmin, superAdminId } = useHttp();
  const { id: patientId } = useParams();

  const patientCtx = React.useContext(PatientCtx);

  const [selectedScale, setSelectedScale] = React.useState({} as Scale);

  return (
    <TableCell>
      <Grid container spacing={2}>
        {ratingScales.map((category, index) => (
          <ScaleResult
            id={category.id}
            name={category.abbreviation}
            setSelectedScale={setSelectedScale}
            patientId={patientId ?? ''}
          />
        ))}
        <PatientScaleDetailsDialog
          selectedRow={
            {
              attemptId: selectedScale?.attemptId,
              categoryId: selectedScale?.categoryId,
              takenOn: selectedScale?.attemptedAt
                ? format(new Date(selectedScale?.attemptedAt), 'M/d/yyyy')
                : '',
              metaInfo: selectedScale.metaInfo,
              score: `${selectedScale?.score}/${selectedScale?.totalScore}`,
              scale: selectedScale?.scale,
              patientName: `${capitalize(patientCtx?.name?.firstName ?? '')} ${capitalize(
                patientCtx?.name?.lastName ?? '',
              )}`,
              patientDOB: googleDateToDateString(patientCtx?.dateOfBirth as GoogleDate),
              providerName: `${capitalize(patientCtx?.provider?.firstName ?? '')} ${capitalize(
                patientCtx?.provider?.lastName ?? '',
              )}`,
            } as unknown as TableData
          }
          setSelectedRow={() => setSelectedScale({} as Scale)}
          showNotes={!(isBillingAdmin || !!superAdminId)}
        />
      </Grid>
    </TableCell>
  );
}
