import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import ErrorBoundary from 'components/Common/ErrorBoundary';
import { FetchContextProvider } from 'hooks/use-fetch';
import Auth0ProviderWithHistory from 'pages/Authentication/components/Auth0-provider-with-history';
import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import Router from 'Router';
import Store from 'Store';
import Theme from 'theme';
import mixpanel from 'mixpanel-browser';
import { isDev } from 'utils/env';
import * as Sentry from '@sentry/react';

if (!isDev && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,

    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({ maskAllText: false }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || '';
  mixpanel.init(mixpanelToken, { debug: true, track_pageview: true, persistence: 'localStorage' });

  return (
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Provider store={Store}>
            <Auth0ProviderWithHistory>
              <FetchContextProvider>
                <ThemeProvider theme={Theme}>
                  <ErrorBoundary>
                    <CssBaseline enableColorScheme />
                    <Router />
                  </ErrorBoundary>
                </ThemeProvider>
              </FetchContextProvider>
            </Auth0ProviderWithHistory>
          </Provider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
