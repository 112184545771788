import mixpanel from 'mixpanel-browser';
import { MixpanelEventName } from 'utils/constants';

const trackMixpanelEvent = (
  eventName: MixpanelEventName,
  payload?: Record<string, string | Record<string, string>>,
) => {
  mixpanel.track(eventName, { 'datetime clicked': new Date().toISOString(), ...payload });
};


export default trackMixpanelEvent;
