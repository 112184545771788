import { WaypointTrackingAPIResponse, WaypointType, WaypointValues } from 'pages/Dashboard/types/waypoints.types';


export const WaypointsAPIMockResponse: WaypointTrackingAPIResponse = {
  waypoints: [
    {
      id: '1',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint1',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Social (overall)',
        displayName: 'Social (overall)',
        description: 'This is waypoint 1',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_LESS,
    },
    {
      id: '2',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint2',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Leisure activities',
        displayName: 'Leisure activities',
        description: 'This is waypoint 2',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: {
          id: 'waypoint1',
          options: [],
          userAudit: {
            createdByUser: {
              id: 'user1',
              name: 'John Doe',
              email: 'john.doe@example.com',
            },
            updatedByUser: {
              id: 'user2',
              name: 'Jane Smith',
              email: 'jane.smith@example.com',
            },
          },
          timeAudit: {
            createdAt: { nanos: 2023434, seconds: 23232325432 },
            updatedAt: { nanos: 2023434, seconds: 23232325432 },
            deletedAt: null,
          },
          name: 'Waypoint 1',
          displayName: 'WP1',
          description: 'This is waypoint 1',
          category: 'Behavior',
          type: WaypointType.WAYPOINT_TYPE_DEFAULT,
          parentWaypoint: null,
        },
      },
      providerId: '0#2223',
      value: WaypointValues.WAYPOINT_VALUE_TYPICAL,
    },
    {
      id: '3',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint3',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Daily responsibilities',
        displayName: 'Daily responsibilities',
        description: 'This is waypoint 3',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: {
          id: 'waypoint1',
          options: [],
          userAudit: {
            createdByUser: {
              id: 'user1',
              name: 'John Doe',
              email: 'john.doe@example.com',
            },
            updatedByUser: {
              id: 'user2',
              name: 'Jane Smith',
              email: 'jane.smith@example.com',
            },
          },
          timeAudit: {
            createdAt: { nanos: 2023434, seconds: 23232325432 },
            updatedAt: { nanos: 2023434, seconds: 23232325432 },
            deletedAt: null,
          },
          name: 'Social (overall)',
          displayName: 'Social (overall)',
          description: 'This is waypoint 1',
          category: 'Behavior',
          type: WaypointType.WAYPOINT_TYPE_DEFAULT,
          parentWaypoint: null,
        },
      },
      providerId: '0#2223',
      value: WaypointValues.WAYPOINT_VALUE_MORE,
    },
    {
      id: '4',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint4',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Work interactions',
        displayName: 'Work interactions',
        description: 'This is waypoint 4',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: {
          id: 'waypoint1',
          options: [],
          userAudit: {
            createdByUser: {
              id: 'user1',
              name: 'John Doe',
              email: 'john.doe@example.com',
            },
            updatedByUser: {
              id: 'user2',
              name: 'Jane Smith',
              email: 'jane.smith@example.com',
            },
          },
          timeAudit: {
            createdAt: { nanos: 2023434, seconds: 23232325432 },
            updatedAt: { nanos: 2023434, seconds: 23232325432 },
            deletedAt: null,
          },
          name: 'Social (overall)',
          displayName: 'Social (overall)',
          description: 'This is waypoint 1',
          category: 'Behavior',
          type: WaypointType.WAYPOINT_TYPE_DEFAULT,
          parentWaypoint: null,
        },
      },
      providerId: '0#2223',
      value: WaypointValues.WAYPOINT_VALUE_TYPICAL,
    },
    {
      id: '5',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint5',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Personal interactions',
        displayName: 'Personal interactions',
        description: 'This is waypoint 5',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: {
          id: 'waypoint1',
          options: [],
          userAudit: {
            createdByUser: {
              id: 'user1',
              name: 'John Doe',
              email: 'john.doe@example.com',
            },
            updatedByUser: {
              id: 'user2',
              name: 'Jane Smith',
              email: 'jane.smith@example.com',
            },
          },
          timeAudit: {
            createdAt: { nanos: 2023434, seconds: 23232325432 },
            updatedAt: { nanos: 2023434, seconds: 23232325432 },
            deletedAt: null,
          },
          name: 'Social (overall)',
          displayName: 'Social (overall)',
          description: 'This is waypoint 1',
          category: 'Behavior',
          type: WaypointType.WAYPOINT_TYPE_DEFAULT,
          parentWaypoint: null,
        },
      },
      providerId: '0#2223',
      value: WaypointValues.WAYPOINT_VALUE_TYPICAL,
    },
    {
      id: '6',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint6',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Diet (consumption)',
        displayName: 'Diet (consumption)',
        description: 'This is waypoint 1',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_MORE,
    },
    {
      id: '7',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint7',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Exercise (overall)',
        displayName: 'Exercise (overall)',
        description: 'This is waypoint 1',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_LESS,
    },
    {
      id: '8',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint8',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Sleep (overall)',
        displayName: 'Sleep (overall)',
        description: 'This is waypoint 1',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_LESS,
    },

    {
      id: '9',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint9',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Alcohol (consumption)',
        displayName: 'Alcohol (consumption)',
        description: 'This is waypoint 1',
        category: 'Substance',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_MORE,
    },
    {
      id: '10',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint10',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'TV (usage)',
        displayName: 'TV (usage)',
        description: 'This is waypoint 1',
        category: 'Digital',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_LESS,
    },
    {
      id: '11',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint11',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Playing chess',
        displayName: 'Playing chess',
        description: 'This is waypoint 1',
        category: 'custom',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_TYPICAL,
    },
  ],
};

export const WaypointsAPIMockResponse2: WaypointTrackingAPIResponse = {
  waypoints: [
    {
      id: '1',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint1',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Social (overall)',
        displayName: 'Social (overall)',
        description: 'This is waypoint 1',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_LESS,
    },
    {
      id: '2',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint2',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Waypoint 2',
        displayName: 'WP2',
        description: 'This is waypoint 2',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: {
          id: 'waypoint1',
          options: [],
          userAudit: {
            createdByUser: {
              id: 'user1',
              name: 'John Doe',
              email: 'john.doe@example.com',
            },
            updatedByUser: {
              id: 'user2',
              name: 'Jane Smith',
              email: 'jane.smith@example.com',
            },
          },
          timeAudit: {
            createdAt: { nanos: 2023434, seconds: 23232325432 },
            updatedAt: { nanos: 2023434, seconds: 23232325432 },
            deletedAt: null,
          },
          name: 'Waypoint 1',
          displayName: 'WP1',
          description: 'This is waypoint 1',
          category: 'Behavior',
          type: WaypointType.WAYPOINT_TYPE_DEFAULT,
          parentWaypoint: null,
        },
      },
      providerId: '0#2223',
      value: WaypointValues.WAYPOINT_VALUE_TYPICAL,
    },
    {
      id: '3',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint3',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Daily responsibilities',
        displayName: 'Daily responsibilities',
        description: 'This is waypoint 3',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: {
          id: 'waypoint1',
          options: [],
          userAudit: {
            createdByUser: {
              id: 'user1',
              name: 'John Doe',
              email: 'john.doe@example.com',
            },
            updatedByUser: {
              id: 'user2',
              name: 'Jane Smith',
              email: 'jane.smith@example.com',
            },
          },
          timeAudit: {
            createdAt: { nanos: 2023434, seconds: 23232325432 },
            updatedAt: { nanos: 2023434, seconds: 23232325432 },
            deletedAt: null,
          },
          name: 'Social (overall)',
          displayName: 'Social (overall)',
          description: 'This is waypoint 1',
          category: 'Behavior',
          type: WaypointType.WAYPOINT_TYPE_DEFAULT,
          parentWaypoint: null,
        },
      },
      providerId: '0#2223',
      value: WaypointValues.WAYPOINT_VALUE_LESS,
    },
    {
      id: '4',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint4',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Work interactions',
        displayName: 'Work interactions',
        description: 'This is waypoint 4',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: {
          id: 'waypoint1',
          options: [],
          userAudit: {
            createdByUser: {
              id: 'user1',
              name: 'John Doe',
              email: 'john.doe@example.com',
            },
            updatedByUser: {
              id: 'user2',
              name: 'Jane Smith',
              email: 'jane.smith@example.com',
            },
          },
          timeAudit: {
            createdAt: { nanos: 2023434, seconds: 23232325432 },
            updatedAt: { nanos: 2023434, seconds: 23232325432 },
            deletedAt: null,
          },
          name: 'Social (overall)',
          displayName: 'Social (overall)',
          description: 'This is waypoint 1',
          category: 'Behavior',
          type: WaypointType.WAYPOINT_TYPE_DEFAULT,
          parentWaypoint: null,
        },
      },
      providerId: '0#2223',
      value: WaypointValues.WAYPOINT_VALUE_LESS,
    },
    {
      id: '5',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint5',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Personal interactions',
        displayName: 'Personal interactions',
        description: 'This is waypoint 5',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: {
          id: 'waypoint1',
          options: [],
          userAudit: {
            createdByUser: {
              id: 'user1',
              name: 'John Doe',
              email: 'john.doe@example.com',
            },
            updatedByUser: {
              id: 'user2',
              name: 'Jane Smith',
              email: 'jane.smith@example.com',
            },
          },
          timeAudit: {
            createdAt: { nanos: 2023434, seconds: 23232325432 },
            updatedAt: { nanos: 2023434, seconds: 23232325432 },
            deletedAt: null,
          },
          name: 'Social (overall)',
          displayName: 'Social (overall)',
          description: 'This is waypoint 1',
          category: 'Behavior',
          type: WaypointType.WAYPOINT_TYPE_DEFAULT,
          parentWaypoint: null,
        },
      },
      providerId: '0#2223',
      value: WaypointValues.WAYPOINT_VALUE_TYPICAL,
    },
    {
      id: '6',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint6',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Diet (consumption)',
        displayName: 'Diet (consumption)',
        description: 'This is waypoint 1',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_TYPICAL,
    },
    {
      id: '7',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint7',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Exercise (overall)',
        displayName: 'Exercise (overall)',
        description: 'This is waypoint 1',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_TYPICAL,
    },
    {
      id: '8',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint8',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Sleep (overall)',
        displayName: 'Sleep (overall)',
        description: 'This is waypoint 1',
        category: 'Behavior',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_MORE,
    },

    {
      id: '9',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint9',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Alcohol (consumption)',
        displayName: 'Alcohol (consumption)',
        description: 'This is waypoint 1',
        category: 'Substance',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_MORE,
    },
    {
      id: '10',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint10',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'TV (usage)',
        displayName: 'TV (usage)',
        description: 'This is waypoint 1',
        category: 'Digital',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_LESS,
    },
    {
      id: '11',
      timeAudit: {
        createdAt: { nanos: 2023434, seconds: 23232325432 },
        updatedAt: { nanos: 2023434, seconds: 23232325432 },
        deletedAt: null,
      },
      userAudit: {
        createdByUser: {
          id: 'user1',
          name: 'John Doe',
          email: 'john.doe@example.com',
        },
        updatedByUser: {
          id: 'user2',
          name: 'Jane Smith',
          email: 'jane.smith@example.com',
        },
      },
      patientId: '12121212',
      waypoint: {
        id: 'waypoint11',
        options: [],
        userAudit: {
          createdByUser: {
            id: 'user1',
            name: 'John Doe',
            email: 'john.doe@example.com',
          },
          updatedByUser: {
            id: 'user2',
            name: 'Jane Smith',
            email: 'jane.smith@example.com',
          },
        },
        timeAudit: {
          createdAt: { nanos: 2023434, seconds: 23232325432 },
          updatedAt: { nanos: 2023434, seconds: 23232325432 },
          deletedAt: null,
        },
        name: 'Playing chess',
        displayName: 'Playing chess',
        description: 'This is waypoint 1',
        category: 'custom',
        type: WaypointType.WAYPOINT_TYPE_DEFAULT,
        parentWaypoint: null,
      },
      providerId: '0#2232',
      value: WaypointValues.WAYPOINT_VALUE_LESS,
    },
  ],
};
