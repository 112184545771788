import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { MoodColorType, MoodOptions, MoodTrackingList } from 'pages/Dashboard/types/moods.types';
import {
  formatIntervalDate,
  getFormattedChartData,
  getGaugeColor,
  getGaugeData,
  getTimeFrameInterval,
} from 'pages/Dashboard/utils/trackingUtils';
import SingleLineChart from 'pages/Dashboard/components/SingleLineChart';
import GaugeChart from 'pages/Dashboard/components/GaugeChart';

const MoodColors: MoodColorType = {
  Happiness: { positive: 'green', negative: 'red' },
  Motivation: { positive: 'green', negative: 'red' },
  Anxiousness: { positive: 'red', negative: 'green' },
};

interface MoodCardProps {
  timeFrame: string;
  cardData: MoodOptions;
  chartData: MoodTrackingList[];
}

export default function MoodCard({ timeFrame, cardData, chartData }: MoodCardProps): JSX.Element {
  const moodDifference = cardData.currentValue - cardData.previousValue;
  const interval = getTimeFrameInterval(timeFrame);
  const formattedChartData = getFormattedChartData(chartData);

  const renderComparison = () => {
    const isPositive = moodDifference > 0;
    const isZero = moodDifference === 0;

    if (isZero) {
      return (
        <Typography textAlign='center' color='gray' variant='h2' fontWeight='400'>
          no change
        </Typography>
      );
    }
    const color = isPositive
      ? MoodColors[cardData.label].positive
      : MoodColors[cardData.label].negative;
    return (
      <>
        {isPositive ? (
          <ArrowDropUp sx={{ mt: '2px', color: `${color}` }} />
        ) : (
          <ArrowDropDown sx={{ mt: '2px', color: `${color}` }} />
        )}
        <Typography textAlign='center' color={color} variant='h2'>
          {isPositive ? `+${moodDifference}` : moodDifference}
        </Typography>
      </>
    );
  };

  return (
    <Grid item xs={12} md={4} key='mood-card'>
      <Card sx={{ height: '100%' }}>
        <CardHeader sx={{ pb: 0 }} title={cardData.label} titleTypographyProps={{ variant: 'h6', align: 'center' }} />
        <CardContent>
          <Stack spacing={3} alignItems='center'>
            <Box width='100%' textAlign='center'>
              <GaugeChart
                color={getGaugeColor(cardData.mood, cardData.currentValue)}
                data={getGaugeData(cardData.currentValue)}
                value={cardData.currentValue.toString()}
              />
              <Typography color='gray'>
                {`average for ${timeFrame.replace('last', 'past')}`}
              </Typography>
              <Typography color='gray'>
                {formatIntervalDate(interval.currInterval)}
              </Typography>
            </Box>

            <Box textAlign='center'>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>{renderComparison()}</Box>
              <Typography color='gray'>
                {`Compared to ${formatIntervalDate(interval.previousInterval)}`}
              </Typography>
            </Box>
            <Box width='100%' textAlign='center'>
              <Typography color='gray'>trends</Typography>
              <SingleLineChart data={formattedChartData} dataKeyX='date' dataKeyLine='value' />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
}
