import { LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip, ResponsiveContainer } from 'recharts';
import * as React from 'react';

interface LineChartComponentProps {
    data: object[],
    dataKeyX: string,
    dataKeyLine: string,
}

export default function SingleLineChart(
  {
    data,
    dataKeyX,
    dataKeyLine,
  }: LineChartComponentProps,
): JSX.Element {
  return (
    <ResponsiveContainer width='100%' height={250}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey={dataKeyX} />
        <YAxis width={20} axisLine={false} />
        <Tooltip wrapperStyle={{ color: 'black' }} />
        <Line type='monotone' dataKey={dataKeyLine} />
      </LineChart>
    </ResponsiveContainer>
  );
}
