import * as React from 'react';
import Box from '@mui/material/Box';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ConsentForm from 'components/Layouts/ConsentForm';
import Spinner from 'components/Common/Spinner';
import Footer from 'components/Layouts/Footer';
import Header from 'components/Layouts/Header';
import SideNav from 'components/Layouts/SideNav';
import { SideNavCtx } from 'components/Layouts/constants';

export default function Borders({ children }: { children: React.ReactNode }) {
  const {
    breakpoints,
    custom: {
      appBarHeight,
      sidebarWidth,
      smSidebarWidth,
      backgroundColor,
    },
  } = useTheme();
  const { isAuthenticated, isLoading } = useAuth0();
  const isFullNav = useMediaQuery(breakpoints.up('lg'));
  const drawerWidth = isFullNav ? sidebarWidth : smSidebarWidth;
  const sideNavCtxValue = React.useMemo(
    () => ({ drawerWidth, isFullNav }),
    [drawerWidth, isFullNav],
  );

  if (isLoading) {
    return <Spinner />;
  }
  else if (!isAuthenticated) {
    return <Box>{children}</Box>;
  }
  return (
    <SideNavCtx.Provider value={sideNavCtxValue}>
      <Header />
      <Box mt={appBarHeight} height={`calc(100vh - ${appBarHeight})`}>
        <ConsentForm>
          <SideNav />
          <Box
            component='main'
            ml={drawerWidth}
            pl={{ xs: 1.5, sm: 2.75, xl: 16 }}
            pt={2}
            pr='8.5%'
            bgcolor={backgroundColor}
            height='100%'
            overflow='auto'
          >
            {children}
            <Footer />
          </Box>
        </ConsentForm>
      </Box>
    </SideNavCtx.Provider>
  );
}
