import { format, parse, subYears } from 'date-fns';

const d = new Date();

export type GoogleDate = {
  year: number;
  month: number;
  day: number;
};

export type Time = {
  hours: number;
  minutes: number;
  seconds: number;
  nanos: number;
}

export type GoogleTimestamp = {
  nanos: number;
  seconds: number;
}

export const browserDateFormat = 'yyyy-MM-dd';


export enum Frequency {
  FREQUENCY_UNSPECIFIED = 'FREQUENCY_UNSPECIFIED',
  FREQUENCY_NA = 'FREQUENCY_NA',
  FREQUENCY_HOURLY = 'FREQUENCY_HOURLY',
  FREQUENCY_DAILY = 'FREQUENCY_DAILY',
  FREQUENCY_WEEKLY = 'FREQUENCY_WEEKLY',
  FREQUENCY_MONTHLY = 'FREQUENCY_MONTHLY',
  FREQUENCY_YEARLY = 'FREQUENCY_YEARLY'
}

export const formattedDates = {
  today: format(d, browserDateFormat),
  age18: format(subYears(d, 18), browserDateFormat),
};

export const formatToBrowserDate = (date: Date) => format(date, browserDateFormat);

export const dateStringToGoogleDate = (dateString: string): GoogleDate => {
  const parsedDate = parse(dateString, browserDateFormat, new Date());
  return {
    year: parsedDate.getFullYear(),
    month: parsedDate.getMonth() + 1,
    day: parsedDate.getDate(),
  };
};

export function googleDateToDateString(googleDate: GoogleDate | null) {
  if (!googleDate) {
    return 'Unknown date';
  }
  const { day, month, year } = googleDate;
  const formatString = `${year}-${month}-${day}`;
  const parsedDateFns = parse(formatString, browserDateFormat, new Date());
  return format(parsedDateFns, 'M/d/yyyy');
}

export function googleTimestampToDateString(googleTimestamp: GoogleTimestamp | string) {
  if (!googleTimestamp) {
    return '';
  }

  if (typeof googleTimestamp === 'string') {
    return googleDateToDateString(dateTimeStringToGoogleDate(googleTimestamp));
  }

  const date = new Date(googleTimestamp.seconds * 1000);
  return format(date, 'M/d/yyyy');
}

export const frequencyFormatter = (frequency: Frequency) => {
  switch (frequency) {
    case Frequency.FREQUENCY_DAILY:
      return 'per day';
    case Frequency.FREQUENCY_HOURLY:
      return 'per hour';
    case Frequency.FREQUENCY_MONTHLY:
      return 'per month';
    case Frequency.FREQUENCY_WEEKLY:
      return 'per week';
    case Frequency.FREQUENCY_YEARLY:
      return 'per year';
    case Frequency.FREQUENCY_UNSPECIFIED:
      return '';
    case Frequency.FREQUENCY_NA:
      return '';
    default:
      return '';
  }
};

export const dateTimeStringToGoogleDate = (
  dateString: string,
): GoogleDate => {
  const date = dateString.split('T');
  const [year, month, day] = date[0].split('-');

  return {
    year: +year,
    month: +month,
    day: +day,
  };
};
