import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from 'react-query';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Typography } from '@mui/material';
import PageHeader from 'components/Common/PageHeader';
import { useHttp } from 'hooks/use-fetch';
import useSearchParamsState from 'hooks/use-search-params-state';
import ListPatientFilters from 'pages/Dashboard/components/ListPatientFilters';
import ListPatients from 'pages/Dashboard/components/ListPatients';
import { RefetchPatientsCtx } from 'pages/Dashboard/constants';
import { PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import usePatientList from 'pages/Dashboard/hooks/usePatientList';
import { fetchProvidersAssociationsList } from 'pages/Dashboard/services/provider.services';
import { providers } from 'utils/apiUrls';
import { mainNav } from 'utils/spaUrls';

export default function DashboardV2() {
  const { http, providerId } = useHttp();

  const [statusFilter, setStatusFilter] = useSearchParamsState('status', PATIENT_LIST_VIEW.READY);
  const [lastNameFilter, setLastNameFilter] = useSearchParamsState('lastname', '');

  const filters = React.useMemo(
    () => ({
      status: statusFilter,
      lastName: lastNameFilter,
    }),
    [lastNameFilter, statusFilter],
  );

  const {
    isFetching,
    isError,
    alphabetFilterList,
    filteredPatientList,
    patientsCount,
    refetchPatientsList,
    refetchPatientsCount,
  } = usePatientList(providerId, filters);

  const providerAssociations = useQuery(
    [`${providers.providerAssociationList.queryUrl}`, providerId, 'dashboard'],
    {
      queryFn: fetchProvidersAssociationsList(http.get),
    },
  );

  const updateStatusFilter = React.useCallback(
    (updatedStatusFilter: string) => {
      setStatusFilter(updatedStatusFilter);
      setLastNameFilter('');
    },
    [setLastNameFilter, setStatusFilter],
  );

  const patientListTotalCount = React.useMemo(
    () => patientsCount[PATIENT_LIST_VIEW.READY] + patientsCount[PATIENT_LIST_VIEW.PENDING],
    [patientsCount],
  );

  const refetchPatients = React.useCallback(() => {
    refetchPatientsList();
    refetchPatientsCount();
  }, [refetchPatientsList, refetchPatientsCount]);

  const refetchPatientsCtx = React.useMemo(
    () => ({
      refetchPatients,
    }),
    [refetchPatients],
  );

  if (patientListTotalCount === 0) {
    return (
      <Box py={7.5} textAlign='center'>
        <Button
          component={RouterLink}
          size='large'
          startIcon={<AddIcon />}
          to={mainNav.addNewPatient}
          variant='contained'
          data-testid='add-first-patient-button'
        >
          ADD YOUR FIRST PATIENT
        </Button>
      </Box>
    );
  }

  return (
    <RefetchPatientsCtx.Provider value={refetchPatientsCtx}>
      <PageHeader title='Dashboard' />
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h2' fontWeight={400}>Patients</Typography>
        </Grid>
        <Grid item xs={12}>
          <ListPatientFilters
            statusFilter={statusFilter}
            lastNameFilter={lastNameFilter}
            alphabetFilterList={alphabetFilterList}
            setLastNameFilter={setLastNameFilter}
            patientsCount={patientsCount}
            updateStatusFilter={updateStatusFilter}
            isFetching={isFetching}
          />
        </Grid>
      </Grid>
      <ListPatients
        filteredPatientList={filteredPatientList}
        isError={isError}
        isFetching={isFetching}
        setStatusFilter={setStatusFilter}
        showProvider={!!providerAssociations.data?.providers?.length}
        statusFilter={statusFilter}
      />
    </RefetchPatientsCtx.Provider>
  );
}
