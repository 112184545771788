import {} from 'pages/Dashboard/stubs/waypoints';
import {
  MoodTrackingAverageResponse,
  MoodTrackingListResponse,
} from 'pages/Dashboard/types/moods.types';
import { WaypointTrackingAPIResponse } from 'pages/Dashboard/types/waypoints.types';
import { getTimeFrameInterval } from 'pages/Dashboard/utils/trackingUtils';
import { QueryFunctionContext } from 'react-query';
import { trackingUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

// eslint-disable-next-line max-len
export const fetchMoodAverage = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MoodTrackingAverageResponse> {
  const [, providerId, patientId, selectTimeFrame, identifier] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  if (identifier === 'current') {
    const { currInterval } = intervals;
    return method(
      trackingUrls.moodAverage.apiUrls(
          providerId as string,
          patientId as string,
          currInterval.start,
          currInterval.end,
      ).list,
    );
  }

  const { previousInterval } = intervals;
  return method(
    trackingUrls.moodAverage.apiUrls(
        providerId as string,
        patientId as string,
        previousInterval.start,
        previousInterval.end,
    ).list,
  );
};

export const fetchMoodChart = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MoodTrackingListResponse> {
  const [, providerId, patientId, selectTimeFrame, mood] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const { currInterval } = intervals;

  return method(
    trackingUrls.moodList.apiUrls(
        providerId as string,
        patientId as string,
        currInterval.start,
        currInterval.end,
        mood as string,
    ).list,
  );
};

export const fetchWaypointTracking = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<WaypointTrackingAPIResponse> {
  const [, providerId, patientId, selectTimeFrame, identifier] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  if (identifier === 'current') {
    const { currInterval } = intervals;
    return method(
      trackingUrls.waypointAverage.apiUrls(
          providerId as string,
          patientId as string,
          currInterval.start,
          currInterval.end,
      ).list,
    );
  }

  const { previousInterval } = intervals;
  return method(
    trackingUrls.waypointAverage.apiUrls(
        providerId as string,
        patientId as string,
        previousInterval.start,
        previousInterval.end,
    ).list,
  );
};
