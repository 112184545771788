import { useTheme } from '@emotion/react';
import { Box, Grid, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import SectionHeader from 'components/Common/SectionHeader';
import { useHttp } from 'hooks/use-fetch';
import WaypointTrackingSection from 'pages/Dashboard/WaypointTrackingSection';
import MoodCard from 'pages/Dashboard/components/MoodCard';
import { PatientCtx } from 'pages/Dashboard/constants';
import { fetchPatientWaypointList } from 'pages/Dashboard/services/waypoints.services';
import {
  Mood,
  MoodOptions,
  MoodTrackingAverageResponse,
  MoodTrackingListResponse,
} from 'pages/Dashboard/types/moods.types';
import { WaypointTrackingAPIResponse } from 'pages/Dashboard/types/waypoints.types';
import {
  TimeFrameOptions,
  getFilteredWaypointsData,
  getMoodOptions,
} from 'pages/Dashboard/utils/trackingUtils';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { wayPointsUrls } from 'utils/apiUrls';

interface PatientTrackingProps {
  timeFrame: string;
  onChangeTimeFrame: (v: string) => void;
  currentMoodData: MoodTrackingAverageResponse;
  previousMoodData: MoodTrackingAverageResponse;
  currentWaypointData: WaypointTrackingAPIResponse;
  previousWaypointData: WaypointTrackingAPIResponse;
  moodChartHappiness: MoodTrackingListResponse;
  moodChartAnxiousness: MoodTrackingListResponse;
  moodChartMotivation: MoodTrackingListResponse;
}

export default function PatientTracking({
  timeFrame,
  onChangeTimeFrame,
  currentMoodData,
  previousMoodData,
  currentWaypointData,
  previousWaypointData,
  moodChartHappiness,
  moodChartAnxiousness,
  moodChartMotivation,
}: PatientTrackingProps) {
  const patient = React.useContext(PatientCtx);
  const { http, providerId } = useHttp();
  const { id: patientId } = useParams();

  const theme = useTheme();

  const fetchPatientWaypointsResult = useQuery(
    [`${wayPointsUrls.listPatientWaypoints.queryUrl}`, patientId],
    {
      queryFn: fetchPatientWaypointList(http.get),
      enabled: !!providerId && !!patientId,
    },
  );

  const hasWaypoints = fetchPatientWaypointsResult?.data?.waypoints?.length;

  if (
    !currentMoodData?.moods
    || !previousMoodData?.moods
    || !currentWaypointData?.waypoints
    || !previousWaypointData?.waypoints
  ) {
    return null;
  }

  const moodOptions: MoodOptions[] = getMoodOptions(currentMoodData.moods, previousMoodData.moods);
  const groupedAllWaypoints = getFilteredWaypointsData(
    currentWaypointData.waypoints,
    previousWaypointData.waypoints,
  );
  const getChartData = (mood: Mood) => {
    if (mood === Mood.MOOD_HAPPINESS) {
      return moodChartHappiness;
    }
    else if (mood === Mood.MOOD_MOTIVATION) {
      return moodChartMotivation;
    }
    return moodChartAnxiousness;
  };

  const totalNumberOfLogs = moodChartHappiness?.moods?.length ?? 0;

  return (
    <>
      {hasWaypoints ? (
        <Box display='flex' alignItems='center' flexDirection='column'>
          <FormControl sx={{ minWidth: `${theme.spacing(30)}` }}>
            <InputLabel id='select-timeframe'>Timeframe</InputLabel>
            <Select
              labelId='select-timeframe'
              value={timeFrame}
              label='TimeFrame'
              onChange={(e) => onChangeTimeFrame(e.target.value)}
            >
              {Object.keys(TimeFrameOptions).map((key) => (
                <MenuItem value={TimeFrameOptions[key as keyof typeof TimeFrameOptions]}>
                  {TimeFrameOptions[key as keyof typeof TimeFrameOptions]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography fontWeight='bold' mt={2}>
            {typeof totalNumberOfLogs === 'number' ? `${totalNumberOfLogs} logs` : null}
          </Typography>
        </Box>
      ) : null}

      <Box mt={4}>
        <SectionHeader title='Mood' />
        {!totalNumberOfLogs ? (
          <Typography color='gray' mt={4}>
            No data exists
            {hasWaypoints ? ' for the selected timeframe.' : '.'}
          </Typography>
        ) : (
          <Grid container spacing={1}>
            {moodOptions
              && moodOptions.map((option) => (
                <MoodCard
                  timeFrame={timeFrame}
                  cardData={option}
                  chartData={getChartData(option.mood)?.moods || []}
                />
              ))}
          </Grid>
        )}
      </Box>

      <WaypointTrackingSection
        patient={patient}
        timeFrame={timeFrame}
        currentWaypoints={currentWaypointData?.waypoints}
        previousWaypoints={previousWaypointData?.waypoints}
        allWaypoints={groupedAllWaypoints}
      />
    </>
  );
}
