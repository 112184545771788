import { Waypoints } from 'pages/Dashboard/types';
import { TimeAudit, UserAudit } from 'types/audit';

export enum WaypointType {
  WAYPOINT_TYPE_UNSPECIFIED = 0,
  WAYPOINT_TYPE_DEFAULT = 1,
  WAYPOINT_TYPE_PATIENT_CREATED = 2,
  WAYPOINT_TYPE_PROVIDER_CREATED = 3,
}

export enum WaypointValues {
  WAYPOINT_VALUE_LESS = 10,
  WAYPOINT_VALUE_TYPICAL = 20,
  WAYPOINT_VALUE_MORE = 30,
}

type WaypointOptionObject = {
  id: string;
  name: string;
  display_name: string;
}


export interface WaypointObject {
  id: string,
  timeAudit: TimeAudit,
  userAudit: UserAudit,
  name: string,
  displayName: string,
  description: string,
  category: string,
  type: WaypointType,
  parentWaypoint: WaypointObject | null
  options: WaypointOptionObject[]
}

export interface WaypointTracking {
  id: string,
  timeAudit: TimeAudit,
  userAudit: UserAudit,
  patientId: string,
  waypoint: WaypointObject,
  providerId: string,
  value: WaypointValues
}

export interface NestedWaypoints {
  waypoint: WaypointObject & { children?: NestedWaypoints[] },
  value: WaypointValues
}

export interface GroupedWaypoints {
  [k: string]: Array<NestedWaypoints>;
}

export interface WaypointTrackingAPIResponse {
  waypoints: WaypointTracking[]
}

export interface ListWaypointAPIResponse {
  waypoints: Waypoints[]
}

export interface TimeInterval {
  start: string;
  end: string;
}
