import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Grid,
} from '@mui/material';
import { TAttemptQuestion, TableData } from 'pages/ScalesManifest/types/scales-manifest.types';
import theme from 'theme';
import GetAppIcon from '@mui/icons-material/GetApp';
import useAttemptDetails from 'pages/ScalesManifest/hooks/useAttemptDetails';
import generatePDF, { Margin } from 'react-to-pdf';
import { StickyNote2 } from '@mui/icons-material';
import { AddNotesDialog } from 'pages/ScalesManifest/AddNotesDialog';
import { Dialog } from 'components/Common/Dialog';
import trackMixpanelEvent from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

interface QATableColumn {
  id: 'question' | 'answer' | 'score';
  label: string;
  width?: string;
  render?: (goToAnswers: () => void) => React.ReactElement;
}

const columns: QATableColumn[] = [
  { id: 'question', label: 'Question', width: '50%' },
  {
    id: 'answer',
    label: 'Answer',
    width: '25%',
  },
  {
    id: 'score',
    label: 'Score',
    width: '25%',
  },
];

type TPatientProps = {
  questions: TAttemptQuestion[];
  details: TableData;
  showNotes?: boolean;
  note?: string;
  targetRef?: React.MutableRefObject<HTMLDivElement | null>;
  metaInfo?: string;
};

export function Patient({
  questions,
  targetRef,
  details,
  showNotes,
  note,
  metaInfo,
}: TPatientProps) {
  const { providerName, patientName, patientDOB, scale, takenOn, score } = details as TableData;

  const metaObject = metaInfo ? JSON.parse(metaInfo ?? '{}') ?? {} : {};
  return (
    <Box>
      <Box ref={targetRef} id='patient-scale-details'>
        <Box display='flex' justifyContent='row' mb={2}>
          <Typography variant='h5' component='h2'>
            {patientName}
          </Typography>
          <Typography color={theme.custom.colors.lightTextSecondary} variant='h6' ml={2}>
            {`     (${patientDOB}) `}
          </Typography>
          <Typography variant='h6' ml={2}>
            {`     ${providerName} `}
          </Typography>
          <Typography color={theme.custom.colors.lightTextSecondary} variant='h6' ml={2}>
            {`     (${scale}  ${takenOn}) `}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid
            display='flex'
            item
            minWidth={300}
            maxWidth={300}
          >
            <Typography
              mr={2}
              color={theme.custom.colors.lightTextSecondary}
              minWidth={160}
              maxWidth={160}
            >
              Score
            </Typography>
            <Typography sx={{ wordWrap: 'break-word' }} maxWidth='calc(100% - 125px)'>
              {score}
            </Typography>
          </Grid>
          {Object.keys(metaObject).map((key:string, index:number) => (key && key !== 'notes' ? (

            <Grid
              display='flex'
              item
              key={key}
              minWidth={300}
              maxWidth={300}
            >
              <Typography
                mr={2}
                color={theme.custom.colors.lightTextSecondary}
                minWidth={160}
                maxWidth={160}
              >
                {key}
              </Typography>
              <Typography sx={{ wordWrap: 'break-word' }} maxWidth='calc(100% - 125px)'>
                {metaObject[key]}
              </Typography>
            </Grid>
          ) : null))}
        </Grid>
        {note ? (
          <Box display='flex' flexDirection='row' mt={2} mb={2}>
            <Typography
              mr={2}
              color={theme.custom.colors.lightTextSecondary}
              minWidth={160}
              maxWidth={160}
            >
              Note
            </Typography>
            <Typography sx={{ wordWrap: 'break-word' }} maxWidth='calc(100% - 125px)'>
              {note}
            </Typography>
          </Box>
        ) : null}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column: QATableColumn) => (
                  <TableCell key={column.id} style={{ width: column.width }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((row: TAttemptQuestion) => (
                <TableRow
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row.questionId}
                  style={{ pageBreakBefore: 'always' }}
                >
                  {columns.map((column: QATableColumn) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} sx={{ padding: 1.5 }}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

type TPatientScaleDetailsDialogProps = {
  selectedRow: TableData;
  showNotes?: boolean;
  setSelectedRow: (row: TableData) => void;
};

function PatientScaleDetailsDialog({
  selectedRow,
  showNotes,
  setSelectedRow,
}: TPatientScaleDetailsDialogProps) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);

  const { attemptDetails, isFetching, refetch } = useAttemptDetails({
    attemptId: selectedRow.attemptId,
    categoryId: selectedRow.categoryId,
  });

  const executePDFExport = () => {
    setIsDownloading(true);
    const patientInitials = selectedRow?.patientName
      .split(' ')
      .map((name) => name[0]?.toUpperCase())
      .join('');
    generatePDF(() => document.getElementById('patient-scale-details'), {
      method: 'save',
      filename: `${patientInitials}_${selectedRow.scale}_${selectedRow.takenOn}.pdf`,
      page: { margin: Margin.LARGE, format: 'A3' },
    }).finally(() => {
      trackMixpanelEvent(MixpanelEventName.DOWNLOAD_PDF_CLICKED, {
        scales: selectedRow.attemptId,
        location: 'Scale details modal',
      });
      setIsDownloading(false);
    });
  };

  const onCloseNotesDialog = (shouldRefetch?: boolean) => {
    setIsNotesDialogOpen(false);
    shouldRefetch && refetch();
  };
  return (
    <>
      <AddNotesDialog
        open={isNotesDialogOpen}
        onClose={onCloseNotesDialog}
        attemptId={selectedRow.attemptId}
        categoryId={selectedRow.categoryId}
        note={attemptDetails?.data?.notes}
      />
      <Dialog
        open={!!selectedRow.attemptId}
        size='lg'
        actionBtnLabel='DOWNLOAD PDF'
        actionBtnLeftIcon={<GetAppIcon />}
        actionBtnLoading={isDownloading}
        actionBtnDisabled={isDownloading || isFetching}
        secondaryActionBtnLoading={isDownloading}
        secondaryActionBtnDisabled={isDownloading || isFetching}
        secondaryActionBtnLabel={
          showNotes ? `${attemptDetails?.data?.notes ? 'EDIT' : 'ADD'} NOTE` : ''
        }
        secondaryActionBtnLeftIcon={<StickyNote2 />}
        secondaryActionBtnHandler={() => setIsNotesDialogOpen(true)}
        actionBtnHandler={executePDFExport}
        onClose={() => setSelectedRow({} as TableData)}
        renderContent={() => (isFetching ? (
          <Box display='flex' alignItems='center' justifyContent='center' minHeight={400}>
            <CircularProgress variant='indeterminate' />
          </Box>
        ) : (
          <Patient
            questions={attemptDetails?.attemptDetails || []}
            details={selectedRow}
            note={attemptDetails?.data?.notes}
            showNotes={showNotes}
            metaInfo={selectedRow?.metaInfo}
          />
        ))}
      />
    </>
  );
}

export default PatientScaleDetailsDialog;
