import { format } from 'date-fns';
import { useHttp } from 'hooks/use-fetch';
import { fetchAttemptDetails } from 'pages/ScalesManifest/services/scales-manifest.services';
import { TAttemptQuestion, TableData } from 'pages/ScalesManifest/types/scales-manifest.types';
import {
  FunctionComponentElement,
  RefObject,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import generatePDF, { Margin } from 'react-to-pdf';
import Theme from 'theme';

interface IUsePDFExport {
  exportingRows: TableData[];
  renderComponent: (
    ref: RefObject<HTMLDivElement>,
    details: TableData,
    questions: TAttemptQuestion[]
  ) => React.ReactElement;
  afterExecution: () => void;
}

const usePDFExport = ({ exportingRows, renderComponent, afterExecution }: IUsePDFExport) => {
  const { http, providerId } = useHttp();
  const [isExecuting, setIsExecuting] = useState(false);
  const targetRef = useRef<HTMLDivElement>(null);

  const executePDFExport = async () => {
    const iterations = [...exportingRows];
    let index = 0;

    const executeNextIteration = async () => {
      if (index < iterations.length) {
        const { patientName, scale, attemptedAt, attemptId, categoryId } = iterations[index];
        const tablesDiv = document.getElementById('tables') as HTMLElement;
        tablesDiv.style.maxWidth = `${Theme.breakpoints.values.lg}px`;

        const attemptDetails = await fetchAttemptDetails(
          http.get,
          attemptId,
          categoryId,
          providerId,
        );

        ReactDOM.render(
          renderComponent(
            targetRef,
            iterations[index],
            attemptDetails.attemptDetails,
          ) as FunctionComponentElement<unknown>,
          tablesDiv,
          async () => {
            const patientInitials = patientName
              .split(' ')
              .map((name) => name[0]?.toUpperCase())
              .join('');
            generatePDF(targetRef, {
              method: 'save',
              filename: `${patientInitials}_${scale}_${format(
                new Date(attemptedAt),
                'M/d/yyyy',
              )}.pdf`,
              page: { margin: Margin.SMALL },
            }).then(async () => {
              ReactDOM.unmountComponentAtNode(tablesDiv);
              index += 1;
              await executeNextIteration();
              if (index === iterations.length) {
                setIsExecuting(false);
                afterExecution();
              }
            });
          },
        );
      }
    };

    setIsExecuting(true);
    await executeNextIteration();
  };

  return {
    executePDFExport,
    isExecuting,
  };
};

export default usePDFExport;
