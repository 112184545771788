import { Button, SxProps, Theme } from '@mui/material';
import Toast, { ToastProps } from 'components/Common/Toast';
import ShowMoreDrawer from 'pages/Dashboard/ShowMoreDrawer';
import ResolveInviteForm from 'pages/Dashboard/components/ResolveInviteForm';
import { INVITE_DRAWER_TYPE } from 'pages/Dashboard/enums';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import React, { memo, useCallback, useState } from 'react';

interface TProps {
  patient: PatientObj;
  sx?: SxProps<Theme>
}

function ResolveInvite({ patient, sx }: TProps) {
  const [drawerItem, setDrawerItem] = useState('');
  const [toastProps, setToastProps] = React.useState<ToastProps | null>(null);

  const openDrawer = useCallback(() => {
    setDrawerItem(INVITE_DRAWER_TYPE.RESOLVE_INVITE);
  }, []);
  const closeDrawer = useCallback(() => {
    setDrawerItem('');
  }, []);

  return (
    <>
      {toastProps && (
      <Toast {...toastProps} autoHideDuration={6000} onClose={() => setToastProps(null)} />
      )}
      <ShowMoreDrawer drawerItem={drawerItem} closeDrawer={closeDrawer}>
        <ResolveInviteForm
          patient={patient}
          drawerItem={drawerItem}
          closeDrawer={closeDrawer}
          setToastProps={setToastProps}
          drawerMode
        />
      </ShowMoreDrawer>
      <Button color='primary' variant='outlined' size='small' onClick={openDrawer} sx={sx}>
        RESOLVE
      </Button>

    </>
  );
}

export default memo(ResolveInvite);
