import { CacheKeysModel } from 'types/CacheKeysModel';

export const CacheKeys: CacheKeysModel = {
  enableDarkMode: 'enable-dark-mode',
  orgId: 'org-id',
  userId: 'user-id',
  providerId: 'super-admin-selected-provider',
};

export const LogoUrl = {
  sm: 'https://res.cloudinary.com/diyfx2bz3/image/upload/v1706894684/favicon_w47qqz.ico',
  lg: 'https://res.cloudinary.com/diyfx2bz3/image/upload/v1706894684/headlamp-health-logo-light-bg_tsykhe.png',
};

export const NotSpecified = 'Not specified';

export const WelcomeExplorerActionId = 'welcome-action';

export enum MixpanelEventName {
  SEE_ANSWERS_CLICKED = 'Rating scales see answers button clicked',
  PATIENT_NAME_CLICKED = 'Rating scales patient name link clicked',
  DOWNLOAD_PDF_CLICKED = 'Rating scales download PDF button clicked',
  FILTERS_APPLIED = 'Rating scales filters applied',
  EXPORT_DATA_CLICKED = 'Export data clicked',
  SCALES_SUGGESTED = 'Patient overview scales suggested',
  SCALE_CLICKED = 'Patient overview rating scale link clicked',
  ADDED_NOTE_ON_SCALE = 'Patient overview rating scale note added',
  REPORTS_ROUTE_VISITED = 'Reports route visited',
}
